import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header/Header";
import { getTasks } from "../api/tasks"; // קריאות API שונות
import {getLeads} from '../api/leads';
import {getUsers} from '../api/user';
import {getCollections} from '../api/collections';
import TaskModal from "../components/Tasks/TaskModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faTimeline } from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Board from "../components/Tasks/Board";
import BoardByDay from "../components/Tasks/calendar";
function Tasks() {
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState([]);
    const [showTasksModal, setShowTasksModal] = useState(false);
    const [projectMembers, setProjectMembers] = useState([]);
    const [allData, setAllData] = useState({ users: [], leads: [], collections: [] });
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [memberType, setMemberType] = useState("users");
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const [users, leads, collections] = await Promise.all([
            getUsers(),
            getLeads(),
            getCollections(),
          ]);
          setAllData({ users, leads, collections });
          setFilteredMembers(users); // ברירת מחדל
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
  
      const fetchTasks = async () => {
        try {
          const fetchedTasks = await getTasks();
          setTasks(fetchedTasks);
        } catch (error) {
          console.error("Error fetching tasks:", error);
        }
      };
  
      fetchTasks();
    }, []);
  
    useEffect(() => {
      // עדכון הפילטר לפי סוג המשתמש
      setFilteredMembers(allData[memberType] || []);
    }, [memberType, allData]);
  
    return (
      <div className="container p-2">
        <Tabs defaultActiveKey="board" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="board" title="לוח">
            <Board
              tasks={tasks}
              setTasks={setTasks}
              setProjectMembers={setProjectMembers}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              showTasksModal={showTasksModal}
              setShowTasksModal={setShowTasksModal}
            />
          </Tab>
          <Tab eventKey="timeline" title="יומן">
            <BoardByDay
              tasks={tasks}
              setTasks={setTasks}
              setProjectMembers={setProjectMembers}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              showTasksModal={showTasksModal}
              setShowTasksModal={setShowTasksModal}
            />
          </Tab>
        </Tabs>
  
        <TaskModal
          showTasksModal={showTasksModal}
          setShowTasksModal={setShowTasksModal}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          tasks={tasks}
          setTasks={setTasks}
          projectMembers={filteredMembers}
          setProjectMembers={setProjectMembers}
          setMemberType={setMemberType}
        />
      </div>
    );
  }
  
  export default Tasks;
  