import { apiUrl } from './config';

const getLeads = async () => {

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/leads', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

export const updateLeadRawData = async (leadId, rawData) => {
    try {
        const token = localStorage.getItem('accessToken');
        
        const response = await fetch(`${apiUrl}/leads/${leadId}/raw-data`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify({ raw_data: rawData })
        });
        
        if (!response.ok) {
            throw new Error('Failed to update raw data');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Error updating lead raw data:', error);
        throw error;
    }
};
const saveLead = async (lead) => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(apiUrl + '/leads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(lead) 
      });
  
      const responseData = await response.json();
  
      return responseData;
  
    } catch (error) {
      return error;
    }
  }

const deleteLead = async (lead_id) => {

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/leads/' + lead_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}
export const updateLead = async (lead) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/leads/${lead.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(lead),
        });
        return await response.json();
    } catch (error) {
        console.error('Error updating lead:', error);
    }
};

export const getLeadsConvertedThisMonth = async () => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/leads/converted-monthly`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response.ok ? await response.json() : 0;
    } catch (error) {
        console.error("Error fetching converted leads:", error);
        return 0;
    }
};
  

export { getLeads, saveLead, deleteLead   };