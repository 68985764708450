import React, { useState, useEffect } from 'react';
import { getProducts, deleteProduct, updateProduct } from '../../api/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faInfoCircle, faBox, faTags, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Button, Modal, Alert, Form } from 'react-bootstrap';
import AddProductModal from '../../components/AddProductModal';
import { useTranslation } from 'react-i18next';
import { getClientsByProduct } from '../../api/clients';

function Products({ userInfo }) {
    const { t } = useTranslation();
    const projectId = localStorage.getItem('project_id');
    const [products, setProducts] = useState([]);
    const [showAddProductModal, setShowAddProductModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productClients, setProductClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [editingProduct, setEditingProduct] = useState({
        name: '',
        description: '',
        price: ''
    });
    const [productStats, setProductStats] = useState({
        totalProducts: 0,
        averagePrice: 0,
        totalCustomers: 0
    });

    useEffect(() => {
        fetchProducts();
    }, [projectId]);

    useEffect(() => {
        calculateStats();
    }, [products]);

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const fetchedProducts = await getProducts(projectId);
            setProducts(fetchedProducts);
            setError(null);
        } catch (error) {
            console.error('Error fetching products:', error);
            setError('אירעה שגיאה בטעינת המוצרים');
        } finally {
            setLoading(false);
        }
    };

    const calculateStats = async () => {
        try {
            let totalCustomers = 0;
            const customersPromises = products.map(product => getClientsByProduct(product.id));
            const customersResults = await Promise.all(customersPromises);
            
            const uniqueCustomers = new Set();
            customersResults.forEach(customers => {
                customers.forEach(customer => {
                    uniqueCustomers.add(customer.id);
                });
            });

            const averagePrice = products.reduce((acc, curr) => acc + Number(curr.price || 0), 0) / products.length;

            setProductStats({
                totalProducts: products.length,
                averagePrice: averagePrice || 0,
                totalCustomers: uniqueCustomers.size
            });
        } catch (error) {
            console.error('Error calculating stats:', error);
        }
    };

    const handleEditClick = (product) => {
        setEditingProduct({
            id: product.id,
            name: product.name,
            description: product.description || '',
            price: product.price || ''
        });
        setShowEditModal(true);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedProduct = await updateProduct(editingProduct.id, editingProduct);
            setProducts(products.map(p => p.id === updatedProduct.id ? updatedProduct : p));
            setShowEditModal(false);
            setEditingProduct({ name: '', description: '', price: '' });
        } catch (error) {
            console.error('Error updating product:', error);
            setError('אירעה שגיאה בעדכון המוצר');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = (product) => {
        setSelectedProduct(product);
        setShowDeleteModal(true);
    };

    const handleInfoClick = async (product) => {
        setSelectedProduct(product);
        try {
            const clients = await getClientsByProduct(product.id);
            setProductClients(clients);
        } catch (error) {
            console.error('Error fetching product clients:', error);
        }
        setShowInfoModal(true);
    };

    const handleDelete = async () => {
        try {
            await deleteProduct(selectedProduct.id);
            setProducts(products.filter(p => p.id !== selectedProduct.id));
            setShowDeleteModal(false);
            setSelectedProduct(null);
        } catch (error) {
            console.error('Error deleting product:', error);
            setError('אירעה שגיאה במחיקת המוצר');
        }
    };

    if (loading && !products.length) {
        return (
            <Container className="text-center p-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">טוען...</span>
                </div>
            </Container>
        );
    }

    return (
        <Container fluid>
            {error && (
                <Alert variant="danger" className="mt-3">
                    {error}
                </Alert>
            )}

            {/* כותרת וכפתור הוספה */}
            <div className="d-flex justify-content-between align-items-center py-4">
                <h2 className="mb-0">ניהול מוצרים</h2>
                <Button variant="primary" onClick={() => setShowAddProductModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    הוסף מוצר
                </Button>
            </div>

            {/* סטטיסטיקות */}
            <Row className="mb-4">
                <Col md={4}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 className="text-muted mb-1">סה"כ מוצרים</h6>
                                    <h3 className="mb-0">{productStats.totalProducts}</h3>
                                </div>
                                <FontAwesomeIcon icon={faBox} className="text-primary fa-2x" />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 className="text-muted mb-1">מחיר ממוצע</h6>
                                    <h3 className="mb-0">₪{productStats.averagePrice.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}</h3>
                                </div>
                                <FontAwesomeIcon icon={faTags} className="text-success fa-2x" />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h6 className="text-muted mb-1">סה"כ לקוחות שרכשו</h6>
                                    <h3 className="mb-0">{productStats.totalCustomers}</h3>
                                </div>
                                <FontAwesomeIcon icon={faUsers} className="text-info fa-2x" />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* רשימת מוצרים */}
            <Row>
                {products.map(product => (
                    <Col md={3} key={product.id} className="mb-4">
                        <Card className="h-100 shadow-sm hover-card">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-3">{product.name}</h5>
                                    <div>
                                        <Button 
                                            variant="link" 
                                            className="text-info p-0 me-2"
                                            onClick={() => handleInfoClick(product)}
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </Button>
                                        <Button 
                                            variant="link" 
                                            className="text-warning p-0 me-2"
                                            onClick={() => handleEditClick(product)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        <Button 
                                            variant="link" 
                                            className="text-danger p-0"
                                            onClick={() => handleDeleteClick(product)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </div>
                                </div>
                                <p className="text-muted mb-3">{product.description || 'אין תיאור'}</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="text-primary mb-0">₪{Number(product.price).toLocaleString()}</h5>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
                {products.length === 0 && !loading && (
                    <Col>
                        <Alert variant="info">
                            אין מוצרים להצגה. לחץ על "הוסף מוצר" כדי להתחיל.
                        </Alert>
                    </Col>
                )}
            </Row>

            {/* מודל מחיקת מוצר */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>מחיקת מוצר</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    האם אתה בטוח שברצונך למחוק את המוצר "{selectedProduct?.name}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        ביטול
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        מחק
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* מודל פרטי מוצר */}
            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>פרטי מוצר - {selectedProduct?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <h6>פרטים כלליים</h6>
                            <hr />
                            <p><strong>מזהה:</strong> {selectedProduct?.id}</p>
                            <p><strong>מחיר:</strong> ₪{Number(selectedProduct?.price).toLocaleString()}</p>
                            <p><strong>תיאור:</strong> {selectedProduct?.description || 'אין תיאור'}</p>
                        </Col>
                        <Col md={6}>
                            <h6>לקוחות שרכשו ({productClients.length})</h6>
                            <hr />
                            {productClients.length > 0 ? (
                                <ul className="list-unstyled">
                                    {productClients.map(client => (
                                        <li key={client.id} className="mb-2">
                                            {client.name} - {client.email || 'אין אימייל'}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p className="text-muted">אין לקוחות שרכשו מוצר זה</p>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowInfoModal(false)}>
                        סגור
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* מודל עריכת מוצר */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header>
                    <Modal.Title>עריכת מוצר</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleEditSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>שם המוצר</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingProduct.name}
                                onChange={(e) => setEditingProduct({...editingProduct, name: e.target.value})}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>מחיר</Form.Label>
                            <Form.Control
                                type="number"
                                value={editingProduct.price}
                                onChange={(e) => setEditingProduct({...editingProduct, price: e.target.value})}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={editingProduct.description}
                                onChange={(e) => setEditingProduct({...editingProduct, description: e.target.value})}
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-end gap-2">
                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                ביטול
                            </Button>
                            <Button variant="primary" type="submit" disabled={loading}>
                                {loading ? 'שומר...' : 'שמור שינויים'}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* מודל הוספת מוצר */}
            <AddProductModal 
                products={products} 
                setProducts={setProducts} 
                showAddProductModal={showAddProductModal} 
                setShowAddProductModal={setShowAddProductModal} 
            />
        </Container>
    );
}

export default Products;