import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Alert, Spinner, Modal, Row, Col } from 'react-bootstrap';
import { getClients, updateClientProduct } from '../../api/clients';
import { getProducts } from '../../api/products';
import { getCollections } from '../../api/collections';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';

const ClientProductTable = ({ projectId }) => {
    const [clients, setClients] = useState([]);
    const [products, setProducts] = useState([]);
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingClient, setEditingClient] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
    const [selectedClientDetails, setSelectedClientDetails] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [clientsData, productsData, collectionsData] = await Promise.all([
                    getClients(),
                    getProducts(projectId),
                    getCollections()
                ]);
                setClients(clientsData);
                setProducts(productsData);
                setCollections(collectionsData);
                setError(null);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('שגיאה בטעינת נתונים. אנא נסה שוב.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [projectId]);

    const handleEditClick = (client) => {
        setEditingClient(client);
        setSelectedProduct(client.product_id || '');
        setShowModal(true);
    };

    const handleViewClientDetails = (client) => {
        setSelectedClientDetails(client);
        setShowClientDetailsModal(true);
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            await updateClientProduct(editingClient.id, selectedProduct);
            
            // Update client in local state
            const updatedClients = clients.map(client => 
                client.id === editingClient.id 
                    ? { ...client, product_id: selectedProduct } 
                    : client
            );
            
            setClients(updatedClients);
            setSuccessMessage('המוצר עודכן בהצלחה ללקוח');
            
            // Close modal after short delay
            setTimeout(() => {
                setShowModal(false);
                setSuccessMessage('');
                setEditingClient(null);
            }, 1500);
        } catch (err) {
            setError('שגיאה בעדכון הלקוח. אנא נסה שוב.');
        } finally {
            setLoading(false);
        }
    };

    const getProductName = (productId) => {
        const product = products.find(p => p.id == productId);
        return product ? product.name : 'לא הוקצה מוצר';
    };

    if (loading && !clients.length) {
        return (
            <div className="text-center p-5">
                <Spinner animation="border" role="status" />
                <p className="mt-2">טוען נתונים...</p>
            </div>
        );
    }

    return (
        <div className="container">
            <h3 className="mb-4">ניהול לקוחות ומוצרים</h3>
            {error && <Alert variant="danger">{error}</Alert>}
            
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>שם לקוח</th>
                        <th>אימייל</th>
                        <th>טלפון</th>
                        <th>מוצר מוקצה</th>
                        <th>פעולות</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.map(client => (
                        <tr key={client.id}>
                            <td>{client.name}</td>
                            <td>{client.email}</td>
                            <td>{client.phone}</td>
                            <td>
                                <span className={client.product_id ? "badge bg-success" : "badge bg-secondary"}>
                                    {getProductName(client.product_id)}
                                </span>
                            </td>
                            <td>
                                <div className="btn-group">
                                    <Button 
                                        variant="outline-info" 
                                        size="sm" 
                                        className="me-1"
                                        onClick={() => handleViewClientDetails(client)}
                                    >
                                        <FontAwesomeIcon icon={faEye} /> צפה
                                    </Button>
                                    <Button 
                                        variant="outline-primary" 
                                        size="sm"
                                        onClick={() => handleEditClick(client)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} /> ערוך
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {clients.length === 0 && !loading && (
                <Alert variant="info">
                    אין לקוחות להצגה.
                </Alert>
            )}

            {/* Modal for editing product assignment */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header>
                    <Modal.Title>הקצאת מוצר ללקוח</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {successMessage && (
                        <Alert variant="success">{successMessage}</Alert>
                    )}
                    
                    {editingClient && (
                        <>
                            <p><strong>לקוח:</strong> {editingClient.name}</p>
                            
                            <Form.Group className="mb-3">
                                <Form.Label>בחר מוצר:</Form.Label>
                                <Form.Select 
                                    value={selectedProduct}
                                    onChange={(e) => setSelectedProduct(e.target.value)}
                                >
                                    <option value="">ללא מוצר</option>
                                    {products.map(product => (
                                        <option key={product.id} value={product.id}>
                                            {product.name} - ₪{product.price}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        <FontAwesomeIcon icon={faTimes} /> ביטול
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> שומר...
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faSave} /> שמור שינויים
                            </>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Client Details Modal */}
            <Modal 
                show={showClientDetailsModal} 
                onHide={() => setShowClientDetailsModal(false)}
                size="lg"
                centered
            >
                <Modal.Header>
                    <Modal.Title>פרטי לקוח</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedClientDetails && (
                        <Row>
                            <Col md={6}>
                                <h5>פרטים כלליים</h5>
                                <hr />
                                <p><strong>שם:</strong> {selectedClientDetails.name}</p>
                                <p><strong>אימייל:</strong> {selectedClientDetails.email || 'לא צוין'}</p>
                                <p><strong>טלפון:</strong> {selectedClientDetails.phone || 'לא צוין'}</p>
                                <p><strong>עיר:</strong> {selectedClientDetails.city || 'לא צוין'}</p>
                                <p><strong>כתובת:</strong> {selectedClientDetails.address || 'לא צוין'}</p>
                                <p><strong>ארגון:</strong> {selectedClientDetails.organization || 'לא צוין'}</p>
                            </Col>
                            <Col md={6}>
                                <h5>מידע על המוצר</h5>
                                <hr />
                                <p>
                                    <strong>מוצר נוכחי:</strong>{' '}
                                    <span className={selectedClientDetails.product_id ? "badge bg-success" : "badge bg-secondary"}>
                                        {getProductName(selectedClientDetails.product_id)}
                                    </span>
                                </p>
                                {selectedClientDetails.product_id && (
                                    <div className="mt-3">
                                        <Button 
                                            variant="outline-primary"
                                            onClick={() => {
                                                setEditingClient(selectedClientDetails);
                                                setSelectedProduct(selectedClientDetails.product_id || '');
                                                setShowClientDetailsModal(false);
                                                setShowModal(true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faEdit} /> שנה מוצר
                                        </Button>
                                    </div>
                                )}
                                
                                <h5 className="mt-4">תשלומים ומידע פיננסי</h5>
                                <hr />
                                {collections.filter(c => c.client_id === selectedClientDetails.id).length > 0 ? (
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>תאריך</th>
                                                <th>סכום</th>
                                                <th>חוב נותר</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {collections
                                                .filter(c => c.client_id === selectedClientDetails.id)
                                                .map(collection => (
                                                <tr key={collection.id}>
                                                    <td>{new Date(collection.collection_date).toLocaleDateString('he-IL')}</td>
                                                    <td>₪{Number(collection.amount).toLocaleString()}</td>
                                                    <td>₪{Number(collection.remaining_debt).toLocaleString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <p>אין רשומות תשלום זמינות ללקוח זה.</p>
                                )}
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowClientDetailsModal(false)}>
                        סגור
                    </Button>
                    {selectedClientDetails && (
                        <Button 
                            variant="primary"
                            onClick={() => {
                                setEditingClient(selectedClientDetails);
                                setSelectedProduct(selectedClientDetails.product_id || '');
                                setShowClientDetailsModal(false);
                                setShowModal(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} /> ערוך פרטי מוצר
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ClientProductTable;