import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CollectionTable from "../components/CRM/collection/collectionTable"; // הטבלה החדשה
import { getCollections, saveCollection, updateCollection } from "../api/collections";
import { getClients } from "../api/clients"; // קריאה ל-API של לקוחות
import { Modal, Button, Form } from "react-bootstrap";

function Collections() {
    const [collections, setCollections] = useState([]);
    const [clients, setClients] = useState([]); // רשימת הלקוחות
    const [showModal, setShowModal] = useState(false);
    const [editingCollection, setEditingCollection] = useState(null);
    const [form, setForm] = useState({
        client_id: "",
        amount: "",
        collection_date: "",
        payments_count: "",
        remaining_debt: "",
    });

    useEffect(() => {
        fetchCollections();
        fetchClients(); // טעינת רשימת הלקוחות
    }, []);

    const fetchCollections = async () => {
        try {
            const data = await getCollections();
            setCollections(data);
        } catch (error) {
            console.error("Error fetching collections:", error);
        }
    };

    const fetchClients = async () => {
        try {
            const data = await getClients(); // קריאה ל-API שמחזיר את רשימת הלקוחות
            setClients(data);
        } catch (error) {
            console.error("Error fetching clients:", error);
        }
    };

    const handleAddCollection = () => {
        setEditingCollection(null);
        setForm({
            client_id: "",
            amount: "",
            collection_date: "",
            payments_count: "",
            remaining_debt: "",
        });
        setShowModal(true);
    };

    const handleEditCollection = (collection) => {
        setEditingCollection(collection);
        setForm({
            client_id: collection.client_id || "",
            amount: collection.amount || "",
            collection_date: collection.collection_date || "",
            payments_count: collection.payments_count || "",
            remaining_debt: collection.remaining_debt || "",
        });
        setShowModal(true);
    };

    const handleSaveCollection = async () => {
        try {
            if (editingCollection) {
                // עריכת גבייה
                await updateCollection(editingCollection.id, form);
            } else {
                // הוספת גבייה
                await saveCollection(form);
            }
            fetchCollections();
            setShowModal(false);
        } catch (error) {
            console.error("Error saving collection:", error);
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">גבייה</h1>
            <button className="btn btn-primary mb-3" onClick={handleAddCollection}>
                <FontAwesomeIcon icon={faPlus} /> הוסף גבייה
            </button>
            <CollectionTable
                collections={collections}
                setCollections={setCollections}
                onEditCollection={handleEditCollection}
                onAddCollection={handleAddCollection}
            />
            {/* המודל */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editingCollection ? "עריכת גבייה" : "הוסף גבייה"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Dropdown בחירת לקוח */}
                        <Form.Group controlId="client_id">
                            <Form.Label>לקוח</Form.Label>
                            <Form.Control
                                as="select"
                                value={form.client_id}
                                onChange={(e) => setForm({ ...form, client_id: e.target.value })}
                            >
                                <option value="">בחר לקוח</option>
                                {clients.map((client) => (
                                    <option key={client.id} value={client.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="amount" className="mt-3">
                            <Form.Label>סכום</Form.Label>
                            <Form.Control
                                type="number"
                                value={form.amount}
                                onChange={(e) => setForm({ ...form, amount: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="collection_date" className="mt-3">
                            <Form.Label>תאריך גבייה</Form.Label>
                            <Form.Control
                                type="date"
                                value={form.collection_date}
                                onChange={(e) =>
                                    setForm({ ...form, collection_date: e.target.value })
                                }
                            />
                        </Form.Group>
                        <Form.Group controlId="payments_count" className="mt-3">
                            <Form.Label>מספר תשלומים</Form.Label>
                            <Form.Control
                                type="number"
                                value={form.payments_count}
                                onChange={(e) => setForm({ ...form, payments_count: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="remaining_debt" className="mt-3">
                            <Form.Label>חוב נותר</Form.Label>
                            <Form.Control
                                type="number"
                                value={form.remaining_debt}
                                onChange={(e) => setForm({ ...form, remaining_debt: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        ביטול
                    </Button>
                    <Button variant="primary" onClick={handleSaveCollection}>
                        שמור
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Collections;
