import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form } from "react-bootstrap";
import { convertLeadToClient } from "../api/clients";
import { getProducts } from "../api/products";

function LeadToClientModal({ show, handleClose, lead, onClientCreated }) {
  const [clientDetails, setClientDetails] = useState({
    phone: "",
    city: "",
    address: "",
    organization: "",
    product_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const projectId = localStorage.getItem('project_id');
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const fetchedProducts = await getProducts(projectId);
        setProducts(fetchedProducts);
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };

    fetchProducts();
  }, []);

  const handleChange = (e) => {
    setClientDetails({ ...clientDetails, [e.target.name]: e.target.value });
  };

  const handleConvert = async () => {
    setLoading(true);
    setError("");

    try {
      const client = await convertLeadToClient(lead.id, clientDetails);
      setLoading(false);
      onClientCreated(client); // קריאה לפונקציה לאחר יצירת הלקוח
      handleClose(); // סגירת המודאל
    } catch (err) {
      setLoading(false);
      setError("Failed to convert lead to client. Please try again.");
      console.error(err);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>המר ליד ללקוח</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>טלפון</Form.Label>
            <Form.Control
              type="text"
              placeholder="הכנס טלפון"
              name="phone"
              value={clientDetails.phone}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCity">
            <Form.Label>עיר</Form.Label>
            <Form.Control
              type="text"
              placeholder="הכנס עיר"
              name="city"
              value={clientDetails.city}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formAddress">
            <Form.Label>כתובת</Form.Label>
            <Form.Control
              type="text"
              placeholder="הכנס כתובת"
              name="address"
              value={clientDetails.address}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formOrganization">
            <Form.Label>שם העסק</Form.Label>
            <Form.Control
              type="text"
              placeholder="הכנס שם העסק"
              name="organization"
              value={clientDetails.organization}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formProduct">
            <Form.Label>מוצר</Form.Label>
            <Form.Select
              name="product_id"
              value={clientDetails.product_id}
              onChange={handleChange}
            >
              <option value="">בחר מוצר</option>
              {products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {error && <div className="text-danger">{error}</div>}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ביטול
        </Button>
        <Button variant="primary" onClick={handleConvert} disabled={loading}>
          {loading ? "ממיר..." : "המר ליד ללקוח"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

LeadToClientModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  onClientCreated: PropTypes.func.isRequired,
};

export default LeadToClientModal;
