import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faPlus, 
    faTrash, 
    faEdit, 
    faSync,
    faUsers,
    faExclamationTriangle 
} from "@fortawesome/free-solid-svg-icons";
import { 
    getIntegrations, 
    createIntegration, 
    updateIntegration, 
    deleteIntegration,
    syncIntegration,
    syncConnectOPUsers
} from "../../api/integrations";
import { Modal, Button, Form } from "react-bootstrap";

function IntegrationsList() {
    const [integrations, setIntegrations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingIntegrationId, setLoadingIntegrationId] = useState(null);
    const [currentIntegration, setCurrentIntegration] = useState({
        name: "",
        provider: "connectop",
        api_key: "",
        api_secret: "",
        api_url: "https://newapp.connectop.co.il",
        sync_frequency: "daily",
        settings: {}
    });

    // טעינת אינטגרציות בטעינת הדף
    useEffect(() => {
        fetchIntegrations();
    }, []);

    // פונקציה לטעינת אינטגרציות מהשרת
    const fetchIntegrations = async () => {
        try {
            setLoading(true);
            const data = await getIntegrations();
            setIntegrations(data);
        } catch (error) {
            console.error("שגיאה בטעינת אינטגרציות:", error);
        } finally {
            setLoading(false);
        }
    };

    // פתיחת חלון הוספת אינטגרציה חדשה
    const handleAddIntegration = () => {
        setIsEditing(false);
        setCurrentIntegration({
            name: "",
            provider: "connectop",
            api_key: "",
            api_secret: "",
            api_url: "https://newapp.connectop.co.il",
            sync_frequency: "daily",
            settings: {}
        });
        setShowModal(true);
    };

    // פתיחת חלון עריכת אינטגרציה קיימת
    const handleEditIntegration = (integration) => {
        setIsEditing(true);
        setCurrentIntegration(integration);
        setShowModal(true);
    };

    // הפעלת סנכרון מלא
    const handleSyncIntegration = async (id) => {
        try {
            setLoadingIntegrationId(id);
            const result = await syncIntegration(id);
            alert("הסנכרון החל בהצלחה. הנתונים יעודכנו בקרוב.");
        } catch (error) {
            console.error("שגיאה בהפעלת סנכרון:", error);
            alert("שגיאה בהפעלת הסנכרון");
        } finally {
            setLoadingIntegrationId(null);
        }
    };

    // הפעלת סנכרון משתמשים בלבד
    const handleSyncUsers = async (id) => {
        try {
            setLoadingIntegrationId(id);
            const result = await syncConnectOPUsers(id);
            
            if (result.success) {
                alert(result.message);
            } else {
                alert("שגיאה בסנכרון משתמשים: " + (result.message || "אירעה שגיאה"));
            }
        } catch (error) {
            console.error("שגיאה בסנכרון משתמשים:", error);
            alert("שגיאה בסנכרון משתמשים");
        } finally {
            setLoadingIntegrationId(null);
        }
    };

    // מחיקת אינטגרציה
    const handleDeleteIntegration = async (id) => {
        if (window.confirm("האם אתה בטוח שברצונך למחוק אינטגרציה זו?")) {
            try {
                await deleteIntegration(id);
                setIntegrations(integrations.filter(integration => integration.id !== id));
            } catch (error) {
                console.error("שגיאה במחיקת אינטגרציה:", error);
                alert("שגיאה במחיקת האינטגרציה");
            }
        }
    };

    // שמירת אינטגרציה (הוספה או עדכון)
    const handleSaveIntegration = async () => {
        try {
            setLoading(true);
            
            if (isEditing) {
                // עדכון אינטגרציה קיימת
                const updatedIntegration = await updateIntegration(
                    currentIntegration.id,
                    currentIntegration
                );
                setIntegrations(
                    integrations.map(integration => 
                        integration.id === updatedIntegration.id ? updatedIntegration : integration
                    )
                );
            } else {
                // יצירת אינטגרציה חדשה
                const newIntegration = await createIntegration(currentIntegration);
                setIntegrations([...integrations, newIntegration]);
            }
            
            setShowModal(false);
        } catch (error) {
            console.error("שגיאה בשמירת אינטגרציה:", error);
            alert("שגיאה בשמירת האינטגרציה");
        } finally {
            setLoading(false);
        }
    };

    // עדכון שדות טופס האינטגרציה
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentIntegration({ ...currentIntegration, [name]: value });
    };

    // פורמט תאריך אחרון לסנכרון
    const formatDate = (dateString) => {
        if (!dateString) return 'מעולם';
        
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('he-IL', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between mt-3 mb-4">
                <h1>ניהול אינטגרציות</h1>
                <Button onClick={handleAddIntegration} disabled={loading}>
                    <FontAwesomeIcon icon={faPlus} /> הוסף אינטגרציה
                </Button>
            </div>

            {loading && !loadingIntegrationId && <div className="text-center my-3">טוען נתונים...</div>}

            <div className="row mt-4">
                {integrations.length === 0 && !loading && (
                    <div className="col-12 text-center">
                        <p>לא נמצאו אינטגרציות. הוסף אינטגרציה חדשה כדי להתחיל.</p>
                    </div>
                )}
                
                {integrations.map(integration => (
                    <div className="col-md-4 mb-4" key={integration.id}>
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">{integration.name}</h5>
                                {integration.status === 'error' && (
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
                                )}
                            </div>
                            <div className="card-body">
                                <p><strong>ספק:</strong> {integration.provider === 'connectop' ? 'ConnectOP' : integration.provider}</p>
                                <p><strong>תדירות סנכרון:</strong> {
                                    integration.sync_frequency === 'hourly' ? 'כל שעה' :
                                    integration.sync_frequency === 'daily' ? 'יומי' : 'שבועי'
                                }</p>
                                <p><strong>סנכרון אחרון:</strong> {formatDate(integration.last_synced_at)}</p>
                                <p><strong>סטטוס:</strong> 
                                    <span className={`badge ${integration.status === 'active' ? 'bg-success' : integration.status === 'error' ? 'bg-danger' : 'bg-secondary'} ms-2`}>
                                        {integration.status === 'active' ? 'פעיל' : 
                                         integration.status === 'error' ? 'שגיאה' : 'לא פעיל'}
                                    </span>
                                </p>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex flex-column">
                                    <Button 
                                        variant="primary" 
                                        onClick={() => handleSyncIntegration(integration.id)}
                                        disabled={loadingIntegrationId !== null || integration.status === 'error'}
                                        className="mb-2"
                                    >
                                        {loadingIntegrationId === integration.id ? (
                                            <>
                                                <FontAwesomeIcon icon={faSync} spin /> מסנכרן...
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faSync} /> סנכרן הכל
                                            </>
                                        )}
                                    </Button>
                                    
                                    {integration.provider === 'connectop' && (
                                        <Button 
                                            variant="info" 
                                            onClick={() => handleSyncUsers(integration.id)}
                                            disabled={loadingIntegrationId !== null || integration.status === 'error'}
                                            className="mb-2"
                                        >
                                            {loadingIntegrationId === integration.id ? (
                                                <>
                                                    <FontAwesomeIcon icon={faSync} spin /> מסנכרן משתמשים...
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faUsers} /> סנכרן משתמשים
                                                </>
                                            )}
                                        </Button>
                                    )}
                                    
                                    <div className="d-flex justify-content-between">
                                        <Button 
                                            variant="warning" 
                                            onClick={() => handleEditIntegration(integration)}
                                        >
                                            <FontAwesomeIcon icon={faEdit} /> ערוך
                                        </Button>
                                        <Button 
                                            variant="danger"
                                            onClick={() => handleDeleteIntegration(integration.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} /> מחק
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditing ? 'ערוך אינטגרציה' : 'הוסף אינטגרציה'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>שם</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={currentIntegration.name}
                                onChange={handleChange}
                                placeholder="שם האינטגרציה"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>ספק</Form.Label>
                            <Form.Select
                                name="provider"
                                value={currentIntegration.provider}
                                onChange={handleChange}
                            >
                                <option value="connectop">ConnectOP</option>
                                {/* אפשר להוסיף ספקים נוספים בעתיד */}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>מפתח API</Form.Label>
                            <Form.Control
                                type="text"
                                name="api_key"
                                value={currentIntegration.api_key}
                                onChange={handleChange}
                                placeholder="מפתח API"
                            />
                            {currentIntegration.provider === 'connectop' && (
                                <Form.Text className="text-muted">
                                    למשל: 1197846.7HM171Yp2Urk8ZLzeAZ22MgPDvX5rSwe549ODX2DvlylX6gG1
                                </Form.Text>
                            )}
                        </Form.Group>

                        {currentIntegration.provider !== "connectop" && (
                            <Form.Group className="mb-3">
                                <Form.Label>סיסמת API</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="api_secret"
                                    value={currentIntegration.api_secret}
                                    onChange={handleChange}
                                    placeholder="סיסמת API (אם נדרש)"
                                />
                            </Form.Group>
                        )}

                        <Form.Group className="mb-3">
                            <Form.Label>כתובת API</Form.Label>
                            <Form.Control
                                type="text"
                                name="api_url"
                                value={currentIntegration.api_url}
                                onChange={handleChange}
                                placeholder="כתובת API"
                            />
                            {currentIntegration.provider === 'connectop' && (
                                <Form.Text className="text-muted">
                                    כתובת ברירת מחדל: https://newapp.connectop.co.il
                                </Form.Text>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>תדירות סנכרון</Form.Label>
                            <Form.Select
                                name="sync_frequency"
                                value={currentIntegration.sync_frequency}
                                onChange={handleChange}
                            >
                                <option value="hourly">כל שעה</option>
                                <option value="daily">יומי</option>
                                <option value="weekly">שבועי</option>
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        ביטול
                    </Button>
                    <Button variant="primary" onClick={handleSaveIntegration} disabled={loading}>
                        {loading ? 'שומר...' : 'שמור'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default IntegrationsList;