import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateTask , saveTask} from "../../api/tasks";
import TaskCard from "./TaskCard";
import CreateTaskCard from "./CreateTaskCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Board({
  tasks,
  setTasks,
  setSelectedTask,
  selectedTask,
  showTasksModal,
  setShowTasksModal,
  projectMembers,
}) {
  const [showAddTaskCard, setShowAddTaskCard] = useState(false);
  const [subject, setSubject] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [assignee, setAssignee] = useState(null);

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const sourceTasks = tasks.filter((task) => task.status === source.droppableId);
    const destTasks = tasks.filter((task) => task.status === destination.droppableId);

    const [movedTask] = sourceTasks.splice(source.index, 1);
    movedTask.status = destination.droppableId;

    destTasks.splice(destination.index, 0, movedTask);

    const updatedTasks = tasks
      .filter((task) => task.status !== source.droppableId && task.status !== destination.droppableId)
      .concat(sourceTasks, destTasks);

    setTasks(updatedTasks);

    try {
      await updateTask(movedTask);
      console.log(`Task ${movedTask.id} updated successfully.`);
    } catch (error) {
      console.error("Failed to update task:", error);
    }
  };

  const handleSaveTask = async () => {
    const newTask = {
      subject,
      description,
      due_date: dueDate,
      phone,
      email,
      assigned_to: assignee,
    };

    try {
      const savedTask = await saveTask(newTask);
      setTasks([savedTask, ...tasks]);
      setShowAddTaskCard(false);
      resetForm();
    } catch (error) {
      console.error("Failed to save task:", error);
    }
  };

  const resetForm = () => {
    setSubject("");
    setDescription("");
    setDueDate("");
    setEmail("");
    setPhone("");
    setAssignee(null);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row scrollable-container">
          <button
            className="btn btn-primary py-2 rounded w-30 small"
            onClick={() => setShowAddTaskCard(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="pe-2 small" />
            הוסף משימה
          </button>

          {showAddTaskCard && (
            <CreateTaskCard
              showAddTaskCard={showAddTaskCard}
              tasks={tasks}
              subject={subject}
              setSubject={setSubject}
              dueDate={dueDate}
              setDueDate={setDueDate}
              email={email}
              setEmail={setEmail}
              phone={phone}
              setPhone={setPhone}
              description={description}
              setDescription={setDescription}
              assignee={assignee}
              setAssignee={setAssignee}
              projectMembers={projectMembers}
              handleHideAddTaskCard={() => setShowAddTaskCard(false)}
              handleSaveTask={handleSaveTask}
            />
          )}

          {["in_progress", "on_hold", "done"].map((status) => (
            <Droppable key={status} droppableId={status}>
              {(provided) => (
                <div
                  className="col-3 p-3"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ borderRight: status !== "done" ? "1px solid #ddd" : "none", overflowY: "auto", height: "90vh" }}
                >
                  <p className="mb-3 fw-500">{getStatusTitle(status)}</p>
                  {tasks
                    .filter((task) => task.status === status)
                    .map((task, index) => (
                      <Draggable key={task.id} draggableId={String(task.id)} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TaskCard
                              task={task}
                              handleShowTaskModal={() => {
                                setSelectedTask(task);
                                setShowTasksModal(true);
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </>
  );
}

function getStatusTitle(status) {
  switch (status) {
    case "in_progress":
      return "עבודה";
    case "on_hold":
      return "הקפאה";
    case "done":
      return "הסתיימו";
    default:
      return "";
  }
}

export default Board;
