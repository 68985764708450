import React, { useState, useEffect, useRef } from "react";
import { updateTask, fetchItemsByType } from "../../api/tasks";
import { getProjects } from "../../api/project";
import profileImagePlaceholder from "../../assets/img/profile.svg";
import UpdateAssigneeDropdown from "./UpdateAssigneeDropdown";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function TaskModal({
  showTasksModal,
  setShowTasksModal,
  selectedTask,
  setSelectedTask,
  tasks,
  setTasks,
  projectMembers,
  setProjectMembers,
}) {
  const { t } = useTranslation();

  const [editedTitle, setEditedTitle] = useState(selectedTask.subject);
  const [editedDescription, setEditedDescription] = useState(selectedTask.description);
  const [editedDueDate, setEditedDueDate] = useState(selectedTask.due_date);
  const [editedPhone, setEditedPhone] = useState(selectedTask.phone);
  const [editedEmail, setEditedEmail] = useState(selectedTask.email);
  const [selectedTaskableType, setSelectedTaskableType] = useState(selectedTask.taskable_type || "");
  const [selectedTaskableId, setSelectedTaskableId] = useState(selectedTask.taskable_id || "");
  const [taskableItems, setTaskableItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(selectedTask.status);
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  const handleCloseTaskModal = () => {
    setShowTasksModal(false);
  };

  useEffect(() => {
    setEditedDueDate(selectedTask.due_date);
    setEditedDescription(selectedTask.description);
    setEditedTitle(selectedTask.subject);
    setEditedPhone(selectedTask.phone);
    setEditedEmail(selectedTask.email);
    setSelectedStatus(selectedTask.status);
    setSelectedTaskableType(selectedTask.taskable_type || "");
    setSelectedTaskableId(selectedTask.taskable_id || "");
  }, [selectedTask]);

  useEffect(() => {
    if (selectedTaskableType) {
      fetchTaskableItems(selectedTaskableType);
    }
  }, [selectedTaskableType]);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchTaskableItems = async (type) => {
    try {
      const items = await fetchItemsByType(type);
      setTaskableItems(items);
    } catch (error) {
      console.error("Failed to fetch taskable items:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const projectsData = await getProjects();
      setProjects(projectsData);
      setFilteredProjects(projectsData);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleBlur = async () => {
    if (editedTitle.trim() === "") {
      return;
    }

    const updatedTask = {
      ...selectedTask,
      subject: editedTitle,
      description: editedDescription,
      due_date: editedDueDate,
      phone: editedPhone,
      email: editedEmail,
      taskable_type: selectedTaskableType,
      taskable_id: selectedTaskableId,
    };

    await updateTask(updatedTask);

    setSelectedTask(updatedTask);

    const updatedTasks = tasks.map((task) => {
      if (task.id === updatedTask.id) {
        return updatedTask;
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const changeTaskStatus = async (status) => {
    setSelectedStatus(status);
    const taskIndex = tasks.findIndex((task) => task.id === selectedTask.id);
    if (taskIndex !== -1) {
      const updatedTasks = [...tasks];
      updatedTasks[taskIndex] = {
        ...updatedTasks[taskIndex],
        status: status,
      };
      setTasks(updatedTasks);
    }

    const updatedTask = {
      ...selectedTask,
      status: status,
    };

    const response = await updateTask(updatedTask);

    if (response.ok) {
      setSelectedTask(updatedTask);
    }
  };

  return (
    <div
      className={`modal fade ${showTasksModal ? "show d-block" : ""}`}
      tabIndex="-1"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: 800, padding: "1.7rem" }}
      >
        <div
          className="modal-content py-3 px-4 border-0 shadow-lg"
          style={{ maxHeight: 900, overflow: "auto" }}
        >
          <div className="modal-header pb-0 border-0 d-flex align-items-center">
            <div>
              <h4 className="modal-title bold m-0" onBlur={handleBlur}>
                <span
                  ref={titleRef}
                  contentEditable
                  suppressContentEditableWarning
                  onBlur={handleBlur}
                  dir="ltr"
                >
                  {editedTitle}
                </span>
              </h4>
              <span className="small text-muted">
                In list <u>{selectedTask.status}</u>
              </span>
            </div>
            <span
              type="button"
              className="close ms-auto text-secondary"
              onClick={handleCloseTaskModal}
              style={{ fontSize: "25pt", fontWeight: "300" }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body py-5">
            <h5>פירוט משימה</h5>
            <textarea
              className="form-control rounded w-100 py-2"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              onBlur={handleBlur}
            />
            <div className="d-flex align-items-center mt-5">
              <h5 className="mb-0 pe-3">שיוך משימה</h5>
              <Form.Select
                value={selectedTaskableType}
                onChange={(e) => setSelectedTaskableType(e.target.value)}
              >
                <option value="">בחר סוג</option>
                <option value="lead">ליד</option>
                <option value="collection">גבייה</option>
              </Form.Select>
            </div>

            {selectedTaskableType && (
              <div className="d-flex align-items-center mt-3">
                <h5 className="mb-0 pe-3">בחר</h5>
                <Form.Select
                  value={selectedTaskableId}
                  onChange={(e) => setSelectedTaskableId(e.target.value)}
                >
                  <option value="">בחר</option>
                  {taskableItems.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}

            <div className="d-flex align-items-center mt-5">
              <h5 className="mb-0 pe-3">תאריך יעד</h5>
              <input
                type="date"
                className="form-control rounded py-2 w-50"
                value={editedDueDate}
                onChange={(e) => setEditedDueDate(e.target.value)}
                onBlur={handleBlur}
              />
            </div>

            <div className="d-flex align-items-center mt-5">
              <h5 className="mb-0 pe-3">טלפון</h5>
              <input
                type="tel"
                className="form-control rounded py-2 w-50"
                value={editedPhone}
                onChange={(e) => setEditedPhone(e.target.value)}
                onBlur={handleBlur}
              />
            </div>

            <div className="d-flex align-items-center mt-5">
              <h5 className="mb-0 pe-3">דוא"ל</h5>
              <input
                type="email"
                className="form-control rounded py-2 w-50"
                value={editedEmail}
                onChange={(e) => setEditedEmail(e.target.value)}
                onBlur={handleBlur}
              />
            </div>

            <div className="d-flex align-items-center mt-5">
              <h5 className="mb-0 pe-3">סטטוס</h5>
              <Form.Select
                value={selectedStatus}
                onChange={(e) => changeTaskStatus(e.target.value)}
              >
                <option value="in_progress">עבודה</option>
                <option value="on_hold">הקפאה</option>
                <option value="done">הסתיים</option>
              </Form.Select>
            </div>

            <div className="pt-3">
              <button className="btn btn-primary" onClick={handleBlur}>
                שמירה
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskModal;
