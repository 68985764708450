import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { getLeads, saveLead, updateLead, deleteLead } from '../api/leads';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Leads() {
    const [leads, setLeads] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentLead, setCurrentLead] = useState(null);

    useEffect(() => {
        const fetchLeads = async () => {
            const fetchedLeads = await getLeads();
            setLeads(fetchedLeads);
        };

        fetchLeads();
    }, []);

    const handleSaveLead = async (lead) => {
        if (lead.id) {
            await updateLead(lead);
        } else {
            const newLead = await saveLead(lead);
            setLeads((prev) => [...prev, newLead]);
        }
        setShowModal(false);
    };

    const handleDeleteLead = async (id) => {
        await deleteLead(id);
        setLeads((prev) => prev.filter((lead) => lead.id !== id));
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between mt-3">
                <h1>ניהול לידים</h1>
                <Button onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={faPlus} /> הוסף ליד
                </Button>
            </div>
            <table className="table mt-3">
                <thead>
                    <tr>
                        <th>שם</th>
                        <th>אימייל</th>
                        <th>טלפון</th>
                        <th>תיאור</th>
                        <th>פעולות</th>
                    </tr>
                </thead>
                <tbody>
                    {leads.map((lead) => (
                        <tr key={lead.id}>
                            <td>{lead.name}</td>
                            <td>{lead.email}</td>
                            <td>{lead.phone}</td>
                            <td>{lead.description}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    onClick={() => {
                                        setCurrentLead(lead);
                                        setShowModal(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeleteLead(lead.id)}
                                    className="ms-2"
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentLead ? 'עריכת ליד' : 'הוסף ליד חדש'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>שם</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={currentLead?.name || ''}
                                onChange={(e) => setCurrentLead({ ...currentLead, name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>אימייל</Form.Label>
                            <Form.Control
                                type="email"
                                defaultValue={currentLead?.email || ''}
                                onChange={(e) => setCurrentLead({ ...currentLead, email: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>טלפון</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={currentLead?.phone || ''}
                                onChange={(e) => setCurrentLead({ ...currentLead, phone: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>תיאור</Form.Label>
                            <Form.Control
                                as="textarea"
                                defaultValue={currentLead?.description || ''}
                                onChange={(e) => setCurrentLead({ ...currentLead, description: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        ביטול
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveLead(currentLead)}>
                        שמור
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Leads;
