import { apiUrl } from './config';



const getTasks = async () => {

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/tasks', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

const saveTask = async (subject, description, due_date, taskable_type, taskable_id) => {
    const data = {
      subject,
      description,
      due_date,
      taskable_type,
      taskable_id,
    };

  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/tasks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};

const assignTo = async (user_id, task_id) => {

    const data = {
        "user_id": user_id,
        "task_id": task_id
    }

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/tasks/assign', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const updateTask = async (updatedTask) => {

    try{

        const token = localStorage.getItem('accessToken');
        const data = updatedTask;

        console.log(data);

        const response = await fetch(apiUrl + '/tasks/' + String(updatedTask.id), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}


const deleteTask = async (contact_id) => {

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/contacts/' + contact_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}
export const fetchItemsByType = async (type) => {
   let apiUrlCurrent = "";

  console.log(type,'type -----------------------------------');
  if (type === "lead") {
    apiUrl = "/leads"; 
  } else if (type === "collection") {
    apiUrlCurrent = "/collections"; 
  } else {
    throw new Error("Invalid type");
  }

  const token = localStorage.getItem("accessToken"); // שימוש בטוקן אם נדרש
  // const response = await fetch(apiUrl, {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // });
  const response = await fetch(apiUrl + apiUrlCurrent, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    },
});


  if (!response.ok) {
    throw new Error(`Failed to fetch ${type}s`);
  }

  return await response.json();
};


export { getTasks, saveTask, updateTask, assignTo, deleteTask  };