import React, { useState } from 'react';
import { Card, Button, InputGroup, FormControl, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faRedo } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiUrl } from '../../api/config';

function ExternalLeadIntegration({ project, onUpdate }) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [copied, setCopied] = useState(false);

  const fullExternalLeadUrl = project.external_lead_code 
    ? `${apiUrl}/external-leads/${project.external_lead_code}`
    : null;

  const generateNewCode = async () => {
    if (window.confirm('Are you sure you want to generate a new code? This will invalidate the previous external lead URL.')) {
      setIsGenerating(true);
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/projects/${project.id}/generate-external-lead-code`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to generate code');
        }
        
        const data = await response.json();
        onUpdate(data.project);
        toast.success('New external lead code generated successfully!');
      } catch (error) {
        toast.error('Failed to generate new code');
        console.error(error);
      } finally {
        setIsGenerating(false);
      }
    }
  };

  const copyToClipboard = () => {
    if (fullExternalLeadUrl) {
      navigator.clipboard.writeText(fullExternalLeadUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      toast.success('URL copied to clipboard!');
    }
  };

  return (
    <Card className="mb-4">
      <Card.Header>
        <h5 className="mb-0">External Lead Integration</h5>
      </Card.Header>
      <Card.Body>
        <p>
          Use this URL to allow external systems to send leads directly to this project. Keep this URL secure as anyone with this URL can submit leads.
        </p>
        
        {project.external_lead_code ? (
          <>
            <InputGroup className="mb-3">
              <FormControl
                value={fullExternalLeadUrl}
                readOnly
              />
              <Button 
                variant="outline-secondary" 
                onClick={copyToClipboard}
                disabled={copied}
              >
                <FontAwesomeIcon icon={faCopy} /> {copied ? 'Copied!' : 'Copy'}
              </Button>
            </InputGroup>
            
            <div className="d-flex justify-content-between">
              <Button 
                variant="outline-danger" 
                size="sm"
                onClick={generateNewCode}
                disabled={isGenerating}
              >
                <FontAwesomeIcon icon={faRedo} /> Regenerate URL
              </Button>
              
              <a 
                href="#" 
                onClick={() => window.open('/external-lead-docs', '_blank')}
                className="text-decoration-none"
              >
                View API Documentation
              </a>
            </div>
          </>
        ) : (
          <div className="text-center">
            <p className="mb-3">No external lead URL has been generated for this project yet.</p>
            <Button 
              variant="primary" 
              onClick={generateNewCode}
              disabled={isGenerating}
            >
              {isGenerating ? 'Generating...' : 'Generate External Lead URL'}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

export default ExternalLeadIntegration;