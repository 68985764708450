// src/pages/admin/ApiIntegrations.jsx
import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faEdit, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getProjects } from '../../api/project';
import ExternalLeadIntegration from '../../components/projects/ExternalLeadIntegration';

function ApiIntegrations() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const fetchedProjects = await getProjects();
        setProjects(fetchedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleUpdateProject = (updatedProject) => {
    setProjects(prevProjects => 
      prevProjects.map(p => p.id === updatedProject.id ? updatedProject : p)
    );
    
    setSelectedProject(updatedProject);
  };

  return (
    <div className="container-fluid py-4" dir="rtl">
      <h2 className="mb-4">ניהול אינטגרציות API</h2>
      
      <div className="row">
        <div className="col-md-5">
          <Card className="mb-4">
            <Card.Header>
              <h5 className="mb-0">פרויקטים זמינים</h5>
            </Card.Header>
            <Card.Body>
              {loading ? (
                <div className="text-center p-4">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">טוען...</span>
                  </div>
                </div>
              ) : (
                <Table hover responsive className="mb-0">
                  <thead>
                    <tr>
                      <th>שם הפרויקט</th>
                      <th>סטטוס API</th>
                      <th>פעולות</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map(project => (
                      <tr key={project.id} className={selectedProject?.id === project.id ? 'table-primary' : ''}>
                        <td>{project.name}</td>
                        <td>
                          {project.external_lead_code ? (
                            <Badge bg="success">מוגדר</Badge>
                          ) : (
                            <Badge bg="secondary">לא מוגדר</Badge>
                          )}
                        </td>
                        <td>
                          <Button 
                            variant="link" 
                            className="p-0 text-primary" 
                            onClick={() => setSelectedProject(project)}
                          >
                            <FontAwesomeIcon icon={faEdit} /> הגדר
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </div>
        
        <div className="col-md-7">
          {selectedProject ? (
            <ExternalLeadIntegration
              project={selectedProject}
              onUpdate={handleUpdateProject}
            />
          ) : (
            <Card className="text-center p-5">
              <Card.Body>
                <FontAwesomeIcon icon={faInfoCircle} size="3x" className="text-muted mb-3" />
                <h5>בחר פרויקט מהרשימה</h5>
                <p className="text-muted">
                  בחר פרויקט מהרשימה משמאל כדי להגדיר או לערוך את הגדרות האינטגרציה החיצונית.
                </p>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApiIntegrations;