import { apiUrl } from './config';

/**
 * קבלת כל האינטגרציות של הפרויקט הנוכחי
 */
const getIntegrations = async () => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/integrations`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * קבלת אינטגרציה ספציפית לפי מזהה
 */
const getIntegration = async (id) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/integrations/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * יצירת אינטגרציה חדשה
 */
const createIntegration = async (integrationData) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/integrations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(integrationData)
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error;
    }
};

/**
 * עדכון אינטגרציה קיימת
 */
const updateIntegration = async (id, integrationData) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/integrations/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(integrationData)
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error;
    }
};

/**
 * מחיקת אינטגרציה
 */
const deleteIntegration = async (id) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/integrations/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error;
    }
};

/**
 * הפעלת סנכרון מיידי
 */
const syncIntegration = async (id) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/integrations/${id}/sync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error;
    }
};

const syncConnectOPUsers = async (id) => {
  try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/integrations/${id}/sync-connectop-users`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
          },
      });

      const responseData = await response.json();
      return responseData;
  } catch (error) {
      return error;
  }
};

export { 
    getIntegrations, 
    getIntegration, 
    createIntegration, 
    updateIntegration, 
    deleteIntegration, 
    syncIntegration,
    syncConnectOPUsers
};