import { apiUrl } from './config';
const updateClient = async (client) => {
    try {
        const token = localStorage.getItem('accessToken');

        const response = await fetch(`${apiUrl}/clients/${client.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(client)
        });

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.errors || "Failed to update client");
        }

        return responseData;
    } catch (error) {
        throw new Error(error.message || "Error updating client");
    }
};
const getClients = async () => {

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/clients', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        if (response.ok) {
            return responseData;
        } else {
            throw new Error(responseData.errors);
        }
    } catch (error) {
        throw new Error(error);
    }

}

const saveClient = async (contact_id) => {

    try {

        const token = localStorage.getItem('accessToken');
        const data = {
            "contact_id": contact_id
        }

        const response = await fetch(apiUrl + '/clients', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}

const deleteClient = async (client_id) => {

    try {

        const token = localStorage.getItem('accessToken'); 

        const response = await fetch(apiUrl + '/clients/' + client_id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        });

        const responseData = await response.json();

        return responseData;

    } catch (error) {
        return error;
    }

}
export const convertLeadToClient = async (leadId, clientDetails) => {
    const token = localStorage.getItem("accessToken");
  
    const response = await fetch(`${apiUrl}/leads/${leadId}/convert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(clientDetails),
    });
  
    if (!response.ok) throw new Error("Failed to convert lead to client");
    return response.json();
  };

  export const updateClientProduct = async (clientId, productId) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/clients/${clientId}/update-product`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ product_id: productId }),
        });

        if (!response.ok) {
            throw new Error('Failed to update client product');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in updateClientProduct:', error);
        throw error;
    }
};

  export const getClientsByProduct = async (productId) => {
    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/products/${productId}/clients`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch clients by product');
        }

        return await response.json();
    } catch (error) {
        console.error('Error in getClientsByProduct:', error);
        throw error;
    }
};

export { getClients, saveClient, deleteClient , updateClient };