// const formatNumberWithCommas = (number) => {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

import React, { useState, useEffect } from 'react';
import { getUserInfo } from '../../api/user';
import { getProjectInfo } from '../../api/project';
import { getSumOfDebt, getMonthlyCollections } from '../../api/collections';
import { getLeadsConvertedThisMonth } from '../../api/leads';
import Calendar from 'react-calendar';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto';

// פונקציה לעיצוב מספר עם פסיקים
const formatNumberWithCommas = (number) => {
    return number.toLocaleString();
};

function DashboardProject() {
    const { t } = useTranslation();
    const [projectInfo, setProjectInfo] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [debt, setDebt] = useState(0);
    const [monthlyCollections, setMonthlyCollections] = useState(0);
    const [leadsConverted, setLeadsConverted] = useState(0);
    const [value, onChange] = useState(new Date());

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const fetchedUserInfo = await getUserInfo();
                setUserInfo(fetchedUserInfo);
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        };

        const fetchProjectInfo = async () => {
            try {
                const fetchedProjectInfo = await getProjectInfo();
                setProjectInfo(fetchedProjectInfo);
            } catch (error) {
                console.error('Error fetching project info:', error);
            }
        };

        const fetchFinancialData = async () => {
            try {
                const totalDebt = await getSumOfDebt();
                setDebt(totalDebt);
                
                const collections = await getMonthlyCollections();
                setMonthlyCollections(collections);

                const convertedLeads = await getLeadsConvertedThisMonth();
                setLeadsConverted(convertedLeads);
            } catch (error) {
                console.error('Error fetching financial data:', error);
            }
        };

        fetchUserInfo();
        fetchProjectInfo();
        fetchFinancialData();
    }, []);

    const lineChartData = {
        labels: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
        datasets: [
            {
                label: 'פרויקטים שהסתיימו',
                data: [1, 3, 10, 7, 8, 12, 15, 15, 13, 14, 12, 17, 21, 23, 18],
                fill: true,
                backgroundColor: 'rgba(0,158,253, 0.2)',
                borderColor: 'rgb(0,158,253)',
                tension: 0.4,
            },
        ],
    };

    const pieChartData = {
        labels: ['גבייה חודשית', 'לקוחות חדשים'],
        datasets: [
            {
                data: [monthlyCollections, leadsConverted],
                backgroundColor: ['#007bff', '#28a745'],
                hoverBackgroundColor: ['#0056b3', '#1d7a2d']
            }
        ]
    };

    return (
        <div className="container-fluid" dir={i18n.language === 'he' ? 'rtl' : 'ltr'}>
            <div className='pt-3'>
                <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
            </div>

            <div className="row pt-3">
                {/* סה"כ חוב */}
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm text-center">
                        <h5>סה"כ חוב</h5>
                        <h4 className="text-danger fw-bold">{formatNumberWithCommas(debt)} ₪</h4>
                    </div>
                </div>

                {/* סך הכל גבייה חודשית */}
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm text-center">
                        <h5>גבייה החודש</h5>
                        <h4 className="text-primary fw-bold">{formatNumberWithCommas(monthlyCollections)} ₪</h4>
                    </div>
                </div>

                {/* לידים שהפכו ללקוחות החודש */}
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm text-center">
                        <h5>לידים הפכו ללקוחות</h5>
                        <h4 className="text-success fw-bold">{leadsConverted}</h4>
                    </div>
                </div>

                {/* גרף קו */}
                <div className="col-md-8">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h6 className="fw-bold">התקדמות פרויקטים</h6>
                        <Line data={lineChartData} />
                    </div>
                </div>

                {/* גרף עוגה */}
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm text-center">
                        <h6 className="fw-bold">חלוקת גבייה ולידים</h6>
                        <Pie data={pieChartData} />
                    </div>
                </div>

                {/* לוח שנה */}
                <div className="col-md-4">
                    <div className="bg-white rounded p-3 mt-3 shadow-sm">
                        <h6 className='fw-bold'>היומן שלי</h6>
                        <Calendar onChange={onChange} value={value} className="w-100 border-0 px-4 my-4" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardProject;
