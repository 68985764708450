import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { getClients, deleteClient } from '../api/clients';
import { getProducts } from '../api/products';
import { getCollections } from '../api/collections';

import { useTranslation } from 'react-i18next';
import { Card, Button, Modal, Form, Row, Col, Table, Alert, Spinner, InputGroup } from 'react-bootstrap';

function Clients({ contacts, setContacts, clients, setClients }) {
    const { t } = useTranslation();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [collections, setCollections] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const fetchedClients = await getClients();
                setClients(fetchedClients);
                setFilteredClients(fetchedClients);
                
                // Fetch supporting data
                const projectId = localStorage.getItem('project_id');
                const [productsData, collectionsData] = await Promise.all([
                    getProducts(projectId),
                    getCollections()
                ]);
                setProducts(productsData);
                setCollections(collectionsData);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('שגיאה בטעינת נתונים');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [setClients]);

    useEffect(() => {
        if (clients) {
            const results = clients.filter(client =>
                (client?.contact?.name && client.contact.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (client?.contact?.email && client.contact.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (client?.contact?.phone && client.contact.phone.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (client?.contact?.organization && client.contact.organization.toLowerCase().includes(searchTerm.toLowerCase()))
            );
            setFilteredClients(results);
        }
    }, [searchTerm, clients]);

    const handleDeleteClient = async (client_id) => {
        if (window.confirm('האם אתה בטוח שברצונך למחוק לקוח זה?')) {
            try {
                await deleteClient(client_id);
                const updatedClients = clients.filter(client => client.id !== client_id);
                setClients(updatedClients);
            } catch (error) {
                console.error('Error deleting client:', error);
                setError('שגיאה במחיקת לקוח');
            }
        }
    };


    const handleViewClient = (client) => {
        setSelectedClient(client);
        setShowViewModal(true);
    };

    const handleEditClient = (client) => {
        setSelectedClient(client);
        setShowEditModal(true);
    };

    const getProductName = (productId) => {
        const product = products.find(p => p.id == productId);
        return product ? product.name : 'לא הוקצה מוצר';
    };

    // Pagination
    const indexOfLastClient = currentPage * itemsPerPage;
    const indexOfFirstClient = indexOfLastClient - itemsPerPage;
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
    const totalPages = Math.ceil(filteredClients.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return (
            <div className="text-center p-5">
                <Spinner animation="border" role="status" />
                <p className="mt-2">טוען נתונים...</p>
            </div>
        );
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'לא זמין';
        const date = new Date(dateString);
        return date.toLocaleDateString('he-IL');
    };

    return (
        <div className="container-fluid py-4">
            {error && <Alert variant="danger">{error}</Alert>}
            
            <Card className="shadow-sm mb-4">
                <Card.Body>
                    {/* <div className="d-flex justify-content-between align-items-center mb-3">
                        <h4 className="mb-0">ניהול לקוחות</h4>
                        <Button 
                            variant="primary" 
                            
                        >
                            <FontAwesomeIcon icon={faPlus} className="me-2" /> לקוח חדש
                        </Button>
                    </div> */}

                    <InputGroup className="mb-3">
                        <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                            placeholder="חיפוש לפי שם, אימייל, טלפון או ארגון..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>

                    <div className="table-responsive">
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>שם</th>
                                    <th>אימייל</th>
                                    <th>טלפון</th>
                                    <th>ארגון</th>
                                    <th>עיר</th>
                                    <th>תאריך הוספה</th>
                                    <th className="text-center">פעולות</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentClients.length > 0 ? (
                                    currentClients.map(client => (
                                        <tr key={client.id}>
                                            <td>{client?.contact?.name || 'לא זמין'}</td>
                                            <td>{client?.contact?.email || 'לא זמין'}</td>
                                            <td>{client?.contact?.phone || 'לא זמין'}</td>
                                            <td>{client?.contact?.organization || 'לא זמין'}</td>
                                            <td>{client?.contact?.city || 'לא זמין'}</td>
                                            <td>{formatDate(client?.created_at)}</td>
                                            <td>
                                                <div className="d-flex justify-content-center">
                                                    <Button 
                                                        variant="outline-info" 
                                                        size="sm" 
                                                        className="me-1"
                                                        onClick={() => handleViewClient(client)}
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                    <Button 
                                                        variant="outline-primary" 
                                                        size="sm" 
                                                        className="me-1"
                                                        onClick={() => handleEditClient(client)}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Button 
                                                        variant="outline-danger" 
                                                        size="sm"
                                                        onClick={() => handleDeleteClient(client.id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center">אין לקוחות להצגה</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    {/* Pagination */}
                    {filteredClients.length > itemsPerPage && (
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <div>
                                <span>מציג {indexOfFirstClient + 1}-{Math.min(indexOfLastClient, filteredClients.length)} מתוך {filteredClients.length} לקוחות</span>
                            </div>
                            <ul className="pagination mb-0">
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <button 
                                            onClick={() => paginate(i + 1)}
                                            className="page-link"
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </Card.Body>
            </Card>

            {/* Add Client Modal */}
            

            {/* View Client Modal */}
            <Modal 
                show={showViewModal} 
                onHide={() => setShowViewModal(false)}
                size="lg"
                centered
                dir="rtl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>פרטי לקוח</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedClient && (
                        <Row>
                            <Col md={6}>
                                <h5>פרטים כלליים</h5>
                                <hr />
                                <p><strong>שם:</strong> {selectedClient?.contact?.name || 'לא זמין'}</p>
                                <p><strong>אימייל:</strong> {selectedClient?.contact?.email || 'לא זמין'}</p>
                                <p><strong>טלפון:</strong> {selectedClient?.contact?.phone || 'לא זמין'}</p>
                                <p><strong>עיר:</strong> {selectedClient?.contact?.city || 'לא זמין'}</p>
                                <p><strong>כתובת:</strong> {selectedClient?.contact?.address || 'לא זמין'}</p>
                                <p><strong>ארגון:</strong> {selectedClient?.contact?.organization || 'לא זמין'}</p>
                                <p><strong>תפקיד:</strong> {selectedClient?.contact?.title || 'לא זמין'}</p>
                                <p><strong>תיאור:</strong> {selectedClient?.contact?.description || 'לא זמין'}</p>
                                <p><strong>תאריך הוספה:</strong> {formatDate(selectedClient?.created_at)}</p>
                            </Col>
                            <Col md={6}>
                                <h5>מידע על המוצר</h5>
                                <hr />
                                <p>
                                    <strong>מוצר נוכחי:</strong>{' '}
                                    <span className={selectedClient.product_id ? "badge bg-success" : "badge bg-secondary"}>
                                        {getProductName(selectedClient.product_id)}
                                    </span>
                                </p>
                                
                                <h5 className="mt-4">תשלומים ומידע פיננסי</h5>
                                <hr />
                                {collections.filter(c => c.client_id === selectedClient.id).length > 0 ? (
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>תאריך</th>
                                                <th>סכום</th>
                                                <th>חוב נותר</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {collections
                                                .filter(c => c.client_id === selectedClient.id)
                                                .map(collection => (
                                                <tr key={collection.id}>
                                                    <td>{formatDate(collection.collection_date)}</td>
                                                    <td>₪{Number(collection.amount).toLocaleString()}</td>
                                                    <td>₪{Number(collection.remaining_debt).toLocaleString()}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <p>אין רשומות תשלום זמינות ללקוח זה.</p>
                                )}
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowViewModal(false)}>
                        סגור
                    </Button>
                    <Button 
                        variant="primary"
                        onClick={() => {
                            setShowViewModal(false);
                            handleEditClient(selectedClient);
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} /> ערוך לקוח
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Client Modal - You'll need to create this component */}
            {selectedClient && (
                <Modal 
                    show={showEditModal} 
                    onHide={() => setShowEditModal(false)}
                    size="lg"
                    centered
                    dir="rtl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>עריכת לקוח</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>שם</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            defaultValue={selectedClient?.contact?.name || ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>אימייל</Form.Label>
                                        <Form.Control 
                                            type="email" 
                                            defaultValue={selectedClient?.contact?.email || ''}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>טלפון</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            defaultValue={selectedClient?.contact?.phone || ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>ארגון</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            defaultValue={selectedClient?.contact?.organization || ''}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>עיר</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            defaultValue={selectedClient?.contact?.city || ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>כתובת</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            defaultValue={selectedClient?.contact?.address || ''}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>תפקיד</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            defaultValue={selectedClient?.contact?.title || ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>מוצר</Form.Label>
                                        <Form.Select 
                                            defaultValue={selectedClient.product_id || ''}
                                        >
                                            <option value="">ללא מוצר</option>
                                            {products.map(product => (
                                                <option key={product.id} value={product.id}>
                                                    {product.name} - ₪{product.price}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="mb-3">
                                <Form.Label>תיאור</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3}
                                    defaultValue={selectedClient?.contact?.description || ''}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                            ביטול
                        </Button>
                        <Button 
                            variant="primary"
                            onClick={() => {
                                // Implement save functionality
                                setShowEditModal(false);
                            }}
                        >
                            שמור שינויים
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default Clients;