import React from "react";
import IntegrationsList from "../components/Integrations/IntegrationsList";
import SocialHeader from "../components/SocialHeader";

function Integrations() {
    return (
        <div id="integrations-page-wrapper">
            <SocialHeader />
            
            <div className="container w-100" style={{ minHeight: '100vh' }}>
                <IntegrationsList />
            </div>
        </div>
    );
}

export default Integrations;