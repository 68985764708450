import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Alert, Spinner, Modal, Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDollarSign, faChartLine, faUsers, faEdit, faSave, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import { getClients, updateClientProduct, saveClient } from '../../api/clients';
import { getProducts } from '../../api/products';
import { getLeads } from '../../api/leads';
import { getCollections } from '../../api/collections';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalesDashboard = () => {
  // State variables for sales dashboard
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [leads, setLeads] = useState([]);
  const [collections, setCollections] = useState([]);
  const [products, setProducts] = useState([]);
  const [showNewSaleModal, setShowNewSaleModal] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [projectId, setProjectId] = useState(localStorage.getItem('project_id'));
  
  // State variables for client-product management
  const [editingClient, setEditingClient] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showProductModal, setShowProductModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showClientDetailsModal, setShowClientDetailsModal] = useState(false);
  const [selectedClientDetails, setSelectedClientDetails] = useState(null);
  
  const [newSale, setNewSale] = useState({
    leadId: '',
    amount: '',
    description: '',
    paymentMethod: '',
    installments: '1'
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const [clientsData, leadsData, collectionsData, productsData] = await Promise.all([
          getClients(),
          getLeads(),
          getCollections(),
          getProducts(projectId)
        ]);
        
        setClients(clientsData);
        setLeads(leadsData);
        setCollections(collectionsData);
        setProducts(productsData);
      } catch (err) {
        setError('אירעה שגיאה בטעינת הנתונים');
        console.error('Error fetching data:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  // Sales dashboard functions
  const calculateSalesData = () => {
    if (!collections.length) return { currentMonth: 0, lastMonth: 0, growth: 0 };

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const currentMonthSales = collections.filter(sale => {
      const saleDate = new Date(sale.collection_date);
      return saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear;
    });

    const lastMonthSales = collections.filter(sale => {
      const saleDate = new Date(sale.collection_date);
      return saleDate.getMonth() === (currentMonth - 1) && saleDate.getFullYear() === currentYear;
    });

    const currentMonthTotal = currentMonthSales.reduce((sum, sale) => sum + Number(sale.amount), 0);
    const lastMonthTotal = lastMonthSales.reduce((sum, sale) => sum + Number(sale.amount), 0);
    
    const growth = lastMonthTotal ? ((currentMonthTotal - lastMonthTotal) / lastMonthTotal) * 100 : 0;

    return {
      currentMonth: currentMonthTotal,
      lastMonth: lastMonthTotal,
      growth: growth.toFixed(1)
    };
  };

  const generateMonthlyData = () => {
    if (!collections.length) return [];

    const monthlyData = {};
    const months = ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 
                   'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'];

    collections.forEach(sale => {
      const date = new Date(sale.collection_date);
      const monthKey = date.getMonth();
      monthlyData[monthKey] = (monthlyData[monthKey] || 0) + Number(sale.amount);
    });

    return months.map((month, index) => ({
      month,
      sales: monthlyData[index] || 0
    }));
  };

  const handleSaleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await saveClient(newSale);
      
      const [clientsData, collectionsData] = await Promise.all([
        getClients(),
        getCollections()
      ]);
      
      setClients(clientsData);
      setCollections(collectionsData);
      setShowNewSaleModal(false);
      
      setNewSale({
        leadId: '',
        amount: '',
        description: '',
        paymentMethod: '',
        installments: '1'
      });
    } catch (err) {
      setError('אירעה שגיאה בשמירת המכירה');
      console.error('Error saving sale:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Client-Product management functions
  const handleEditClick = (client) => {
    setEditingClient(client);
    setSelectedProduct(client.product_id || '');
    setShowProductModal(true);
  };

  const handleViewClientDetails = (client_id) => {
    const client = clients.find(c => c.id === client_id);
    if (client) {
      setSelectedClientDetails(client);
      setShowClientDetailsModal(true);
    } else {
      setError('לא ניתן למצוא את פרטי הלקוח');
    }
  };

  const handleSaveProduct = async () => {
    try {
      setIsLoading(true);
      await updateClientProduct(editingClient.id, selectedProduct);
      
      // Update client in local state
      const updatedClients = clients.map(client => 
        client.id === editingClient.id 
          ? { ...client, product_id: selectedProduct } 
          : client
      );
      
      setClients(updatedClients);
      setSuccessMessage('המוצר עודכן בהצלחה ללקוח');
      
      // Close modal after short delay
      setTimeout(() => {
        setShowProductModal(false);
        setSuccessMessage('');
        setEditingClient(null);
      }, 1500);
    } catch (err) {
      setError('שגיאה בעדכון הלקוח. אנא נסה שוב.');
    } finally {
      setIsLoading(false);
    }
  };

  const getProductName = (productId) => {
    const product = products.find(p => p.id == productId);
    return product ? product.name : 'לא הוקצה מוצר';
  };

  // Chart configurations
  const salesData = calculateSalesData();
  const monthlyData = generateMonthlyData();

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const lineChartData = {
    labels: monthlyData.map(item => item.month),
    datasets: [{
      label: 'מכירות חודשיות',
      data: monthlyData.map(item => item.sales),
      fill: true,
      backgroundColor: 'rgba(0,123,255,0.2)',
      borderColor: 'rgb(0,123,255)',
      tension: 0.4,
    }]
  };

  const barChartData = {
    labels: ['חודש קודם', 'החודש'],
    datasets: [{
      label: 'השוואת מכירות',
      data: [salesData.lastMonth, salesData.currentMonth],
      backgroundColor: 'rgb(0,123,255)',
    }]
  };

  if (isLoading && !clients.length) {
    return (
      <Container fluid className="p-3">
        <div className="text-center p-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">טוען...</span>
          </div>
          <p className="mt-2">טוען נתונים...</p>
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="p-3" dir="rtl">
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-4"
      >
        <Tab eventKey="dashboard" title="דשבורד מכירות">
          {/* Dashboard Content */}
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2>דשבורד מכירות</h2>
            <Button variant="primary" onClick={() => setShowNewSaleModal(true)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              מכירה חדשה
            </Button>
          </div>

          {/* Cards */}
          <Row className="mb-4">
            <Col md={4}>
              <Card className="h-100">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">מכירות החודש</h6>
                      <h3>₪{salesData.currentMonth.toLocaleString()}</h3>
                    </div>
                    <FontAwesomeIcon icon={faDollarSign} className="text-primary fa-2x" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">צמיחה חודשית</h6>
                      <h3>{salesData.growth}%</h3>
                    </div>
                    <FontAwesomeIcon icon={faChartLine} className="text-success fa-2x" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="h-100">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">לקוחות פעילים</h6>
                      <h3>{clients.length}</h3>
                    </div>
                    <FontAwesomeIcon icon={faUsers} className="text-info fa-2x" />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Charts */}
          <Row className="mb-4">
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>מגמת מכירות חודשית</Card.Title>
                  <div style={{ height: '300px' }}>
                    <Line data={lineChartData} options={chartOptions} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>השוואת מכירות</Card.Title>
                  <div style={{ height: '300px' }}>
                    <Bar data={barChartData} options={chartOptions} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Sales Table */}
          <Card>
            <Card.Body>
              <Card.Title>מכירות אחרונות</Card.Title>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>לקוח</th>
                      <th>סכום</th>
                      <th>תאריך</th>
                      <th>סטטוס תשלום</th>
                      <th>פעולות</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collections.slice(0, 5).map((collection) => {
                      const client = clients.find(c => c.id === collection.client_id);
                      return (
                        <tr key={collection.id}>
                          <td>{client?.name || 'לא ידוע'}</td>
                          <td>₪{Number(collection.amount).toLocaleString()}</td>
                          <td>{new Date(collection.collection_date).toLocaleDateString('he-IL')}</td>
                          <td>
                            <span className={`badge bg-${collection.remaining_debt > 0 ? 'warning' : 'success'}`}>
                              {collection.remaining_debt > 0 ? 'בתהליך' : 'הושלם'}
                            </span>
                          </td>
                          <td>
                            <div className="btn-group">
                              {client && (
                                <>
                                  <Button 
                                    variant="outline-info" 
                                    size="sm" 
                                    className="me-1"
                                    onClick={() => handleViewClientDetails(client.id)}
                                  >
                                    <FontAwesomeIcon icon={faEye} /> צפה
                                  </Button>
                                  <Button 
                                    variant="outline-primary" 
                                    size="sm"
                                    onClick={() => handleEditClick(client)}
                                  >
                                    <FontAwesomeIcon icon={faEdit} /> ערוך
                                  </Button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Tab>

        <Tab eventKey="clientProducts" title="ניהול לקוחות ומוצרים">
          {/* Client-Product Management Tab */}
          <div className="container mt-3">
            <h3 className="mb-4">ניהול לקוחות ומוצרים</h3>
            {error && <Alert variant="danger">{error}</Alert>}
            
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>שם לקוח</th>
                  <th>אימייל</th>
                  <th>טלפון</th>
                  <th>מוצר מוקצה</th>
                  <th>פעולות</th>
                </tr>
              </thead>
              <tbody>
                {clients.map(client => (
                  <tr key={client.id}>
                    <td>{client.name}</td>
                    <td>{client.email}</td>
                    <td>{client.phone}</td>
                    <td>
                      <span className={client.product_id ? "badge bg-success" : "badge bg-secondary"}>
                        {getProductName(client.product_id)}
                      </span>
                    </td>
                    <td>
                      <div className="btn-group">
                        <Button 
                          variant="outline-info" 
                          size="sm" 
                          className="me-1"
                          onClick={() => handleViewClientDetails(client.id)}
                        >
                          <FontAwesomeIcon icon={faEye} /> צפה
                        </Button>
                        <Button 
                          variant="outline-primary" 
                          size="sm"
                          onClick={() => handleEditClick(client)}
                        >
                          <FontAwesomeIcon icon={faEdit} /> ערוך
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {clients.length === 0 && !isLoading && (
              <Alert variant="info">
                אין לקוחות להצגה.
              </Alert>
            )}
          </div>
        </Tab>
      </Tabs>

      {/* Sales Modal */}
      <Modal show={showNewSaleModal} onHide={() => setShowNewSaleModal(false)} dir="rtl" size="lg">
        <Modal.Header>
          <Modal.Title>יצירת מכירה חדשה</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>בחר ליד</Form.Label>
              <Form.Select 
                value={newSale.leadId}
                onChange={(e) => setNewSale({...newSale, leadId: e.target.value})}
                required
              >
                <option value="">בחר ליד</option>
                {leads.map((lead) => (
                  <option key={lead.id} value={lead.id}>
                    {lead.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>סכום</Form.Label>
              <Form.Control
                type="number"
                value={newSale.amount}
                onChange={(e) => setNewSale({...newSale, amount: e.target.value})}
                placeholder="הכנס סכום"
                required
                min="0"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>אמצעי תשלום</Form.Label>
              <Form.Select
                value={newSale.paymentMethod}
                onChange={(e) => setNewSale({...newSale, paymentMethod: e.target.value})}
                required
              >
                <option value="">בחר אמצעי תשלום</option>
                <option value="credit">כרטיס אשראי</option>
                <option value="cash">מזומן</option>
                <option value="check">צ'ק</option>
                <option value="transfer">העברה בנקאית</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>תשלומים</Form.Label>
              <Form.Control
                type="number"
                min="1"
                value={newSale.installments}
                onChange={(e) => setNewSale({...newSale, installments: e.target.value})}
                placeholder="מספר תשלומים"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>תיאור</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={newSale.description}
                onChange={(e) => setNewSale({...newSale, description: e.target.value})}
                placeholder="תיאור המכירה"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewSaleModal(false)}>
            ביטול
          </Button>
          <Button variant="primary" type="submit" onClick={handleSaleSubmit} disabled={isLoading}>
            {isLoading ? 'שומר...' : 'צור מכירה'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Product Assignment Modal */}
      <Modal show={showProductModal} onHide={() => setShowProductModal(false)} centered>
        <Modal.Header>
          <Modal.Title>הקצאת מוצר ללקוח</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {successMessage && (
            <Alert variant="success">{successMessage}</Alert>
          )}
          
          {editingClient && (
            <>
              <p><strong>לקוח:</strong> {editingClient.name}</p>
              
              <Form.Group className="mb-3">
                <Form.Label>בחר מוצר:</Form.Label>
                <Form.Select 
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="">ללא מוצר</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name} - ₪{product.price}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowProductModal(false)}>
            <FontAwesomeIcon icon={faTimes} /> ביטול
          </Button>
          <Button 
            variant="primary" 
            onClick={handleSaveProduct}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> שומר...
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSave} /> שמור שינויים
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Client Details Modal */}
      <Modal 
        show={showClientDetailsModal} 
        onHide={() => setShowClientDetailsModal(false)}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title>פרטי לקוח</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedClientDetails && (
            <Row>
              <Col md={6}>
                <h5>פרטים כלליים</h5>
                <hr />
                <p><strong>שם:</strong> {selectedClientDetails.name}</p>
                <p><strong>אימייל:</strong> {selectedClientDetails.email || 'לא צוין'}</p>
                <p><strong>טלפון:</strong> {selectedClientDetails.phone || 'לא צוין'}</p>
                <p><strong>עיר:</strong> {selectedClientDetails.city || 'לא צוין'}</p>
                <p><strong>כתובת:</strong> {selectedClientDetails.address || 'לא צוין'}</p>
                <p><strong>ארגון:</strong> {selectedClientDetails.organization || 'לא צוין'}</p>
              </Col>
              <Col md={6}>
                <h5>מידע על המוצר</h5>
                <hr />
                <p>
                  <strong>מוצר נוכחי:</strong>{' '}
                  <span className={selectedClientDetails.product_id ? "badge bg-success" : "badge bg-secondary"}>
                    {getProductName(selectedClientDetails.product_id)}
                  </span>
                </p>
                {selectedClientDetails.product_id && (
                  <div className="mt-3">
                    <Button 
                      variant="outline-primary"
                      onClick={() => {
                        setEditingClient(selectedClientDetails);
                        setSelectedProduct(selectedClientDetails.product_id || '');
                        setShowClientDetailsModal(false);
                        setShowProductModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} /> שנה מוצר
                    </Button>
                  </div>
                )}
                
                <h5 className="mt-4">תשלומים ומידע פיננסי</h5>
                <hr />
                {collections.filter(c => c.client_id === selectedClientDetails.id).length > 0 ? (
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>תאריך</th>
                        <th>סכום</th>
                        <th>חוב נותר</th>
                      </tr>
                    </thead>
                    <tbody>
                      {collections
                        .filter(c => c.client_id === selectedClientDetails.id)
                        .map(collection => (
                        <tr key={collection.id}>
                          <td>{new Date(collection.collection_date).toLocaleDateString('he-IL')}</td>
                          <td>₪{Number(collection.amount).toLocaleString()}</td>
                          <td>₪{Number(collection.remaining_debt).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>אין רשומות תשלום זמינות ללקוח זה.</p>
                )}
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowClientDetailsModal(false)}>
            סגור
          </Button>
          {selectedClientDetails && (
            <Button 
              variant="primary"
              onClick={() => {
                setEditingClient(selectedClientDetails);
                setSelectedProduct(selectedClientDetails.product_id || '');
                setShowClientDetailsModal(false);
                setShowProductModal(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} /> ערוך פרטי מוצר
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Error message if any */}
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default SalesDashboard;