import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faEdit, faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { getLeads, deleteLead, updateLead } from "../api/leads";
import AddLeadModal from "../components/CRM/Leads/AddLeadModal";
import LeadToClientModal from "../components/LeadToClient";
import { Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function LeadsTable({ leads, setLeads }) {
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showAddLeadModal, setShowAddLeadModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConvertModal, setShowConvertModal] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [editedLead, setEditedLead] = useState({});

  // טוען את הלידים למסך
  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const fetchedLeads = await getLeads(); // קריאה ל-API
        setLeads(fetchedLeads);
        setFilteredLeads(fetchedLeads); // טעינת הלידים גם לטבלת החיפוש
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchLeads();
  }, []);

  // חיפוש בזמן אמת
  useEffect(() => {
    const results = leads.filter((lead) =>
      Object.values(lead).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredLeads(results);
  }, [searchTerm, leads]);

  const handleDeleteLead = async (leadId) => {
    try {
      await deleteLead(leadId);
      const updatedLeads = leads.filter((lead) => lead.id !== leadId);
      setLeads(updatedLeads);
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  };

  const handleEditLead = (lead) => {
    setSelectedLead(lead);
    setEditedLead({ ...lead }); // העתקת נתוני הליד לעריכה
    setShowEditModal(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updatedLead = await updateLead(editedLead); // שמירת השינויים בשרת
      const updatedLeads = leads.map((lead) =>
        lead.id === updatedLead.id ? updatedLead : lead
      );
      setLeads(updatedLeads);
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleConvertLead = (lead) => {
    setSelectedLead(lead);
    setShowConvertModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLeads.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredLeads.length / itemsPerPage);

  return (
    <div className="container">
      <div className="d-flex justify-content-center pt-3 pb-4">
        <button
          className="btn btn-basic bg-white shadow-sm medium"
          onClick={() => setShowAddLeadModal(true)}
        >
          <span className="text-primary">
            <FontAwesomeIcon icon={faPlus} className="pe-1" /> הוספת ליד חדש
          </span>
        </button>
      </div>
      <input
        type="text"
        placeholder="חיפוש"
        value={searchTerm}
        onChange={handleSearch}
        className="form-control my-3 border border-primary"
      />
      <div
        className="bg-white rounded p-3 shadow-sm"
        style={{ overflowX: "auto" }}
      >
        <h6 className="bold mb-3">לידים</h6>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>שם</th>
              <th>אימייל</th>
              <th>טלפון</th>
              <th>תיאור</th>
              <th>מקור ליד</th>
              <th>סטטוס</th>
              <th>שם העסק</th>
              <th className="text-center">פעולות</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((lead) => (
              <tr key={lead.id}>
                <td>{lead.name}</td>
                <td>{lead.email}</td>
                <td>{lead.phone}</td>
                <td>{lead.description}</td>
                <td>{lead.lead_source}</td>
                <td>{lead.status}</td>
                <td>{lead.organization}</td>
                <td className="text-center">
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="px-1">
                      <button
                        className="btn btn-warning text-white"
                        onClick={() => handleEditLead(lead)}
                      >
                        <FontAwesomeIcon icon={faEdit} /> ערוך
                      </button>
                    </div>
                    <div className="px-1">
                      <button
                        className="btn btn-danger text-white"
                        onClick={() => handleDeleteLead(lead.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> מחק
                      </button>
                    </div>
                    <div className="px-1">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleConvertLead(lead)}
                      >
                        <FontAwesomeIcon icon={faExchangeAlt} /> המרה ללקוח
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex flex-column justify-content-between mb-3">
          <div className="col-md-3 ml-1">
            <label>שורות בעמוד</label>
            <select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="form-select"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
            </select>
          </div>
          <label className="mr-2 mt-2">דפדף בין עמודים</label>
          <div className="d-flex flex-row col-md-8 mt-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`btn ${
                  currentPage === index + 1 ? "btn-primary" : "btn-light"
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      <AddLeadModal
        showAddLeadModal={showAddLeadModal}
        setShowAddLeadModal={setShowAddLeadModal}
        leads={leads}
        setLeads={setLeads}
      />

      {selectedLead && (
        <LeadToClientModal
          show={showConvertModal}
          handleClose={() => setShowConvertModal(false)}
          lead={selectedLead}
          onClientCreated={(client) => {
            console.log("לקוח נוצר בהצלחה:", client);
            setShowConvertModal(false);
          }}
        />
      )}
    </div>
  );
}

export default LeadsTable;
