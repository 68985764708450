import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { deleteCollection } from '../../../api/collections';
import { useTranslation } from 'react-i18next';

function CollectionTable({ collections, setCollections, onEditCollection, onAddCollection }) {
    const { t } = useTranslation();
    const [filteredCollections, setFilteredCollections] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    useEffect(() => {
        setFilteredCollections(collections);
    }, [collections]);

    useEffect(() => {
        const results = collections.filter(collection =>
            Object.values(collection).some(
                value => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredCollections(results);
    }, [searchTerm, collections]);

    const handleDeleteCollection = async (collectionId) => {
        try {
            await deleteCollection(collectionId);
            const updatedCollections = collections.filter(collection => collection.id !== collectionId);
            setCollections(updatedCollections);
        } catch (error) {
            console.error('Error deleting collection:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCollections.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCollections.length / itemsPerPage);

    return (
        <div className="container">
            <div className="d-flex justify-content-center pt-3 pb-4">
                <button className="btn btn-basic bg-white shadow-sm medium" onClick={onAddCollection}>
                    <span className="text-primary">
                        {/* <FontAwesomeIcon icon={faPlus} className="pe-1" /> {t('Add Collection')} */}
                    </span>
                </button>
            </div>
            <input 
                type="text" 
                placeholder="חיפוש "
                value={searchTerm} 
                onChange={handleSearch} 
                className="form-control my-3 border border-primary" 
            />
            <div className="bg-white rounded p-3 shadow-sm" style={{ overflowX: 'auto' }}>
                <h6 className="bold mb-3">גבייה</h6>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th className="text-secondary bg-transparent fw-500">{t('Client Name')}</th>
                            <th className="text-secondary bg-transparent fw-500">נגבה</th>
                            <th className="text-secondary bg-transparent fw-500"> חוב קיים</th>
                            <th className="text-secondary bg-transparent fw-500">{t('Status')}</th>
                            <th className="text-center text-secondary bg-transparent fw-500">{t('Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map(collection => (
                            <tr key={collection.id}>
                                  <td className="bg-transparent">
                                    {collection.client ? collection.client.name : ""}
                                </td>
                                <td className="bg-transparent">{collection.amount}</td>
                                 <td className="bg-transparent">{collection.remaining_debt}</td>
                               <td className="bg-transparent">{collection.collection_date}</td>
                               {/* <td></td> */}
                                <td className="bg-transparent">
                                    <div className="h-100 d-flex align-items-center justify-content-center">
                                        <div className="px-1">
                                            <button className="btn btn-basic" onClick={() => onEditCollection(collection)}>
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </div>
                                        <div className="px-1">
                                            <button className="btn btn-basic text-danger" onClick={() => handleDeleteCollection(collection.id)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="d-flex flex-column justify-content-between mb-3">
                    <div className="col-md-3 ml-1">
                        <label>{t('Items per page')}</label>
                        <select value={itemsPerPage} onChange={handleItemsPerPageChange} className="form-select">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                        </select>
                    </div>
                    <label className="mr-2 mt-2">{t('Navigate pages')}</label>
                    <div className="d-flex flex-row col-md-8 mt-2">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CollectionTable;
