import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../api/user';
import { getProjectInfo } from '../../api/project';
import Calendar from 'react-calendar';
import ProductsTable from '../../components/projectManagement/ClientProductTable'; // טבלת העברת לקוחות בין מוצרים
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';

function ProjectManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const fetchedUserInfo = await getUserInfo();
        setUserInfo(fetchedUserInfo);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const lineChartData = {
    labels: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
    datasets: [
      {
        label: 'כמות פרוייקטים פי חודשים',
        data: [1, 3, 10, 7, 8, 12, 15, 15, 13, 14, 12, 17, 21, 23, 18],
        fill: true,
        backgroundColor: 'rgba(0,158,253, 0.2)',
        borderColor: 'rgb(0,158,253)',
        tension: 0.4,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div dir="rtl" className="container-fluid">
      <div className='pt-3'>
        <h3 className='mt-3 fw-500'>{t('greeting.welcome')}, {userInfo && userInfo.name.split(" ")[0]}!</h3>
      </div>

      <div className="row pt-3">
        <div className="col-md-12">
          <div className="bg-white rounded p-3 mt-3 shadow-sm">
          <ProductsTable projectId={userInfo?.currently_selected_project_id} />

          </div>
        </div>
        {/* <div className="col-md-8">
          <div className="bg-white rounded p-3 mt-3 shadow-sm">
            <Line data={lineChartData} options={options} />
          </div>
        </div> */}
        <div className="col-md-4">
          <div className="bg-white rounded p-3 mt-3 shadow-sm">
            <h6 className='bold mb-3'>{t('card_title.my_calendar')}</h6>
            <Calendar onChange={onChange} value={value} className="w-100 border-0 px-4 my-4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectManagement;
